import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useLifeCycleObjectPaginated from './useLifeCycleObjectPaginated';
import SearchButton from '../../../components/utils/SearchButton';
import PageBanner from '../../../components/utils/PageBanner';
import SimpleAlert from '../../../components/utils/SimpleAlert';
import ProgressDialog from '../../../components/utils/ProgressDialog';
import BrandObjectTablePaginated from '../MachineTelemetry/BrandObjectTablePaginated';
import DateRangePicker from '../../../components/utils/DateRangePicker';
import { useProject } from '../../../config/ProjectContext';
import PaginationComponent from '../../../components/Pagination';
import { DEPLOYMENT_STATUS } from '../../../utils/constants';
import { calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    minWidth: 120,
    marginRight: 20,
  },
  formFieldSelect: {
    width: '100%',
    minWidth: 120,
    height: 50,
  },
  formFieldDate: {
    width: '100%',
    minWidth: 320,
  },
  contentInfo: {
    padding: '24px',
    backgroundColor: theme.palette.background.typography,
  },
  bottomRightButton: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    width: 138,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
    margin: 12,
  },
}));

export default function DeploymentDetails() {
  const { project } = useProject();
  const { deploymentID, detailStartDate } = useParams();
  const classes = useStyles();

  const [params, setParams] = useState({
    isError: false,
    status: '',
    exitCode: '',
    prjSpecExitCode: '',
    countryCode: '',
    hw: '',
    fw: '',
    groupBy: '',
    sortDirection: '',
  });

  const { handleSubmit } = useForm();

  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'));
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  const [selectedStartTime, setSelectedStartTime] = useState(moment().format('HH:mm'));
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));
  const [alertOpen, setAlertOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data, isLoading, error, refetch,
  } = useLifeCycleObjectPaginated(
    'machinelist',
    project,
    deploymentID,
    params,
    selectedStartDate,
    selectedEndDate,
    selectedStartTime,
    selectedEndTime,
    currentPage,
    pageSize,
  );

  const deploymentData = data?.data;
  const pagination = data?.pagination;

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setParams({ ...params, [name]: value });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    if (!detailStartDate) return;
    const parsedStartDate = moment.unix(detailStartDate);
    if (moment(parsedStartDate).isValid()) {
      setSelectedStartDate(parsedStartDate);
      setSelectedStartTime(moment(parsedStartDate).set({ hour: 0, minute: 0 }).format('HH:mm'));
    }
  }, [detailStartDate]);

  const onSubmit = () => {
    refetch();
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={isLoading} header="Retrieving statistics, please wait" />
      {error && <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header="Error" body={error.message} />}
      <PageBanner title="DEPLOYMENT DETAILS" />
      <Grid container spacing={0} style={{ padding: '40px' }}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
          <Grid item container spacing={2} direction="row" className={classes.topContent}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputSubtitleLabel}>
                List of devices and modules impacted by a deployment with the current deployment status
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={2}>

              <FormControl className={classes.formField}>
                <InputLabel>Is Error</InputLabel>
                <Select
                  name="isError"
                  value={params.isError}
                  onChange={handleInputChange}
                >
                  <MenuItem value="false">Normal</MenuItem>
                  <MenuItem value="true">Error</MenuItem>
                  {/* Add other status options as needed */}
                </Select>
              </FormControl>

            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth className={classes.formField}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  name="status"
                  value={params.status || ''}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.entries(DEPLOYMENT_STATUS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="exitCode"
                label="Exit Code"
                value={params.exitCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="prjSpecExitCode"
                label="Project-Specific Exit Code"
                value={params.prjSpecExitCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="countryCode"
                label="Country Code"
                value={params.countryCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="hw"
                label="Hardware (hw)"
                value={params.hw}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="fw"
                label="Firmware (fw)"
                value={params.fw}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl className={classes.formField}>
                <InputLabel>Group By</InputLabel>
                <Select name="groupBy" value={params.groupBy} onChange={handleInputChange}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="country_code">Country Code</MenuItem>
                  <MenuItem value="asset_exit_code">Asset Exit Code</MenuItem>
                  <MenuItem value="asset_project_exit_code">Asset Project Exit Code</MenuItem>
                  <MenuItem value="hw">Hardware (hw)</MenuItem>
                  <MenuItem value="fw">Firmware (fw)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl className={classes.formField}>
                <InputLabel>Sort Direction</InputLabel>
                <Select name="sortDirection" value={params.sortDirection} onChange={handleInputChange}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="ASC">Ascending</MenuItem>
                  <MenuItem value="DESC">Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Existing Date Range Picker */}
            <Grid item xs={12} className={classes.datePicker}>
              <DateRangePicker
                initialStartDate={new Date()}
                initialEndDate={new Date()}
                onDateChange={handleDateChange}
                setSelectedStartDate={setSelectedStartDate}
                selectedStartDate={selectedStartDate}
                setSelectedStartTime={setSelectedStartTime}
                selectedStartTime={selectedStartTime}
                setSelectedEndDate={setSelectedEndDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndTime={setSelectedEndTime}
                selectedEndTime={selectedEndTime}
                datesOptional
                setVisibility={false}
                isEndDateEnabled={false}
              />
            </Grid>

            <Grid item container justifyContent="flex-end" xs={12} md={12} className={classes.bottomRightButton}>
              <SearchButton id="btnDeploymentDetailsSearch" type="submit" className={classes.bottomRightButton}>
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <BrandObjectTablePaginated
          dataList={deploymentData}
          loading={isLoading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          object={`machinelist/${project.code}/${deploymentID}`}
          params={JSON.stringify(params)}
          id="DeploymentDetails"
        />
        <div className={classes.tableIcons}>

          <PaginationComponent
            pageSize={pageSize}
            handleSelectChange={handleSelectChange}
            pagination={pagination}
            handlePageChange={handlePageChange}
          />

        </div>

      </Grid>
    </Box>
  );
}
