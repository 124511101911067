/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactJson from 'react-json-view';
import { useMutation, useQuery } from '@tanstack/react-query';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import PageBanner from '../../components/utils/PageBanner';
import cleanAllNullValues from '../../utils/cleanAllNullValues';
import { useSnackbar } from '../../providers/SnackbarContext';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import usePollingGetIotMessages from '../../hooks/usePollingGetIotMessages';
import LoadingAnimation from '../../components/LoadingAnimation';
import replacePropertyValue from '../../utils/replacePropertyValueUtils';

const useStyles = makeStyles((theme) => ({
  wizardContainer: {
    padding: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  payloadArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  selectDeviceMessage: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  payloadTitle: {
    marginTop: theme.spacing(3),
  },
  responseLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const DeviceMessages = [
  {
    topicNumber: 1,
    description: 'Device Telemetry',
    payload: {
      P: 5,
      MID: '',
      MK: 'ES',
      B: 99,
      T: 1,
      M: {
        DVI: {
          MOD: 'ModelA',
          HWR: '1.3.0',
          FWR: '3.4.0',
          SDK: '1.5.0',
          TZ: 'Europe/Madrid',
          UTCF: false,
          XTR: {
            FOO1: 'value_1',
            REV: [
              { DT: 'main', HWR: '1.0.0', FWR: '1.0.0' },
            ],
            LOC: { LAT: 45.464664, LON: 9.18854, REG: 'Milan' },
          },
        },
        GWI: {
          GWID: 'CB8630FC-14DB-497B-88C6-22B2A482443C',
          MOD: 'iPhone11',
          GWV: '3.4.0',
          SDK: '3.4.0',
          APP: '1.2.0',
          MSL: '1.2.0',
        },
        SET: {
          MainTemperature: 21.6,
          WifiStrength: 3.4,
          CellStrength: 2.1,
        },
        CNS: [
          {
            RC: '2',
            CC: '0123456789000000',
            PC: 'BlackCoffee',
            CRON: 123123123,
            BTS: true,
            XTR: { WaterPercentage: 85 },
          },
        ],
        EV: [
          {
            EC: '2',
            CRON: 123123123,
            BTS: true,
            XTR: { LED: 'value_1', DoorOpened: true },
          },
        ],
      },
    },
  },
  {
    topicNumber: 3,
    description: 'On-Boarding Request',
    payload: {
      P: 2,
      MID: '',
      MK: 'ES',
      B: 99,
      T: 3,
    },
  },
  {
    topicNumber: 4,
    description: 'FOTA - Check For Updates',
    payload: {
      P: 5,
      MID: '',
      MK: 'ES',
      B: 99,
      T: 4,
      M: {
        DM: 0,
        FV: '2.0',
        N: '1234567890',
        LM: 1,
        REV: [
          { DT: 'main', HWR: '1.0.0', FWR: '1.0.0' },
          { DT: 'connectivity', HWR: '3.2.1', FWR: '1.2.3' },
        ],
      },
    },
  },
  {
    topicNumber: 7,
    description: 'Market Context Detection',
    payload: {
      P: 3,
      MID: '',
      MK: 'XX',
      B: 99,
      T: 7,
      M: '123.234.123.234',
    },
  },
  {
    topicNumber: 10,
    description: 'Connectivity Test',
    payload: {
      P: 2,
      MID: 'testdevice001',
      MK: 'ES',
      B: 99,
      T: 10,
      M: {
        S: 'connectivitytest',
      },
    },
  },
  {
    topicNumber: 15,
    description: 'IoT Shadow - Report State',
    payload: {
      P: 1,
      MID: 'testdevice001',
      MK: 'ES',
      B: 99,
      T: 15,
      M: {
        state_1: 2,
        state_2: 'OK',
        led: {
          rgb: [
            252,
            3,
            3,
          ],
          enabled: false,
        },
      },
    },
  },
  {
    topicNumber: 16,
    description: 'FOTA - Lifecycle Control',
    payload: {
      P: 2,
      MID: '',
      MK: 'ES',
      B: 99,
      T: 16,
      M: {
        RID: '002c9238-9903-4ed1-8da6-54661df86983',
        AL: [
          {
            DT: 'TYPEA',
            TS: 1732290313,
            ST: 'VALIDATION',
            EC: 0,
            SR: true,
          },
        ],
      },
    },
  },
  {
    topicNumber: 22,
    description: 'Secure Remote Functions - Nonce Delivery',
    payload: {
      P: 1,
      MID: 'testdevice001',
      MK: 'ES',
      B: 99,
      T: 22,
      M: {
        N: 1234567890,
      },
    },
  },
  {
    topicNumber: 23,
    description: 'Secure Remote Functions -  Function Delivery',
    payload: {
      P: 1,
      MID: 'testdevice001',
      B: 99,
      MK: 'ES',
      T: 23,
      M: {
        SM: 'eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJyaWQiOiJhMDYyN2VlMC1jNzQ3LTRkYjYtOGZmNy01YmYyZmI3NjBlYzQiLCJzdDoiOiJPSyIsImV4cCI6MTczMjY0MzEyMywiaXNzIjoiOTlfdGVzdGRldmljZTAwMSIsImlhdCI6MTczMjU2Njc4MiwiZnJlcyI6eyJyZXN1bHQiOiJzdWNjZXNzIn19.',
      },
    },
  },
  {
    topicNumber: 24,
    description: 'Object Ingestion',
    payload: {
      P: 3,
      MID: 'testdevice001',
      B: 99,
      MK: 'ES',
      T: 24,
      M: {
        OT: 'log',
      },
    },
  },
  {
    topicNumber: 25,
    description: 'Configuration Hub',
    payload: {
      MID: 'testdevice001',
      B: 99,
      MK: 'ES',
      T: 25,
      P: 3,
      M: {
        TS: 1648139508111,
      },
    },
  },
  {
    topicNumber: 29,
    description: 'Near Real-Time Notification',
    payload: {
      P: 1,
      MID: 'testdevice001',
      MK: 'ES',
      B: 99,
      T: 29,
      M: {
        SUB: 1,
        PL: 'Sample Payload',
      },
    },
  },
];

const DeviceToCloud = () => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useState('');
  const [payload, setPayload] = useState('');
  const [deviceMessage, setDeviceMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [showResponse, setShowResponse] = useState(false);
  const [showTopic, setShowTopic] = useState(false);
  const [showWaitingResponse, setShowWaitingResponse] = useState(false);

  const [topicSubscription, setTopicSubscription] = useState('');

  const { project } = useProject();
  const openSnackBar = useSnackbar();

  const { iotMessages, startPolling, stopPolling } = usePollingGetIotMessages({
    project,
    serialNumber,
    topicNumber: deviceMessage ? deviceMessage.topicNumber : null,
  });

  useEffect(() => {
    if (showTopic) {
      startPolling();
    }

    return () => {
      stopPolling();
    };
  }, [showTopic]);

  useEffect(() => {
    if (iotMessages && Object.keys(iotMessages).length > 1) {
      setShowResponse(true);
      setShowWaitingResponse(false);
    }
  }, [iotMessages]);

  const onboardingDeviceMutation = useMutation(
    () => GraphqlService.onboardingDevice(

      {
        projectId: project.code,
        serialNumber,
        topicNumber: deviceMessage.topicNumber,
        payload,
      },
    ),
    {
      onSuccess: (result) => {
        openSnackBar(`Message sent successfully: ${result}`, 'success');
      },
      onError: (error) => {
        openSnackBar(`There has been an error sending your message: ${error.message}`, 'error');
        setShowResponse(false);
      },
    },
  );

  const subscribeToTopic = useMutation(
    () => GraphqlService.subscribeToTopic({
      projectId: project.code,
      serialNumber,
      topicNumber: deviceMessage.topicNumber,
    }),
    {
      onSuccess: (result) => {
        const topicSubscribed = `messages/${project.code}/${deviceMessage.topicNumber}/${project.code}_${serialNumber}/c2d`;
        setTopicSubscription(topicSubscribed);
        setShowTopic(true);
        openSnackBar(`Subscribed to topic: ${topicSubscribed}`, 'success');
        onboardingDeviceMutation.mutate();
      },
      onError: (error) => {
        openSnackBar(`There has been an error subscribing to the topic: ${error.message}`, 'error');
      },
    },
  );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboard!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  const handleReset = () => {
    setSerialNumber('');
    setPayload('');
    setDeviceMessage(null);
    setIsJsonValid(true);
    setShowResponse(false);
    setShowTopic(false);
    setTopicSubscription('');
    setShowWaitingResponse(false);
  };

  const handlePayloadChange = (event) => {
    const inputValue = event.target.value;

    try {
      JSON.parse(inputValue);
      setIsJsonValid(true);
      // if MID property change, update the serial number
      const newPayload = JSON.parse(inputValue);
      if (newPayload.MID) {
        setSerialNumber(newPayload.MID);
      }
    } catch {
      setIsJsonValid(false);
    }

    setPayload(inputValue);
  };

  const handleDeviceMessage = (event) => {
    setDeviceMessage(event.target.value);
    const newPayload = event.target.value.payload;
    // Set property CRON and TS with unix timestamp
    const PayloadWithCron = replacePropertyValue(newPayload, 'CRON', moment().unix());
    const PayloadWithTS = replacePropertyValue(PayloadWithCron, 'TS', moment().unix());
    const PayloadWithN = replacePropertyValue(PayloadWithTS, 'N', Math.floor(Math.random() * 1000000));
    // add the serial number if it exists
    if (serialNumber) {
      PayloadWithN.MID = serialNumber;
    }
    setPayload(JSON.stringify(PayloadWithN, null, 2));
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleSend = () => {
    subscribeToTopic.mutate();
    setShowWaitingResponse(true);
  };

  const onChangeSerialNumber = (e) => {
    const inputValue = e.target.value;
    setSerialNumber(inputValue);

    try {
      // Try parsing the payload and updating the MID property
      const newPayload = JSON.parse(payload || '{}'); // Fallback to an empty object if payload is invalid
      newPayload.MID = inputValue;
      setPayload(JSON.stringify(newPayload, null, 2));
    } catch (error) {
      console.error('Invalid JSON in payload:', error);
      // Optionally reset the payload to a default structure
      setPayload(JSON.stringify({ MID: inputValue }, null, 2));
    }
  };

  return (
    <Box className={classes.wizardContainer} id="cloud-to-device">
      <PageBanner title="DEVICE TO CLOUD" />
      <Box mt={2}>
        <Typography>
          Define a Device and select a Simulated Client function.
        </Typography>

      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>

          <Box mt={2}>
            <InputLabel htmlFor="serialNumber">
              Device Serial
              <Typography component="span" color="error">
                *
              </Typography>
            </InputLabel>
            <TextField
              id="device-serial"
              value={serialNumber || ''}
              onChange={onChangeSerialNumber}
              variant="outlined"
              style={{ width: '100%', marginTop: '8px', backgroundColor: 'white' }}
            />
          </Box>

          <Box mt={2}>
            <InputLabel htmlFor="message-type">
              Message type
              <Typography component="span" color="error">
                *
              </Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              variant="outlined"
              id="demo-simple-select-filled"
              value={deviceMessage}
              onChange={handleDeviceMessage}
              className={classes.selectDeviceMessage}
            >
              {DeviceMessages.map((message) => (
                <MenuItem key={message.topicNumber} value={message}>
                  {`${message.topicNumber} - ${message.description}`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel htmlFor="payload" className={classes.payloadTitle}>
            Payload
          </InputLabel>
          {isEditing ? (
            <TextField
              id="payload-editor"
              value={payload}
              onChange={handlePayloadChange}
              multiline
              rows={10}
              variant="outlined"
              className={classes.payloadArea}
              fullWidth
              error={!isJsonValid}
              helperText={!isJsonValid ? 'Invalid JSON format' : ''}
            />
          ) : (
            <Box className={classes.textArea}>

              <ReactJson
                src={payload ? cleanAllNullValues(JSON.parse(payload)) : {}}
                theme="rjv-default"
                collapsed={false}
                displayDataTypes={false}
                enableClipboard
              />
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={toggleEditMode}
                className={classes.button}
                disabled={!isJsonValid}
              >
                {isEditing ? 'View as JSON' : 'Edit JSON'}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleReset}
                className={classes.button}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!isJsonValid || !serialNumber || !deviceMessage}
                onClick={handleSend}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box>
        {showTopic && (
          <Box mt={3}>
            <InputLabel htmlFor="topic">
              Topic
            </InputLabel>
            <TextField
              id="topic"
              value={topicSubscription}
              variant="outlined"
              readOnly
              InputProps={{
                readOnly: true,
                endAdornment: topicSubscription && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => copyToClipboard(topicSubscription)}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', marginTop: '8px', backgroundColor: 'white' }}
            />
          </Box>
        )}
        {
          showWaitingResponse && !showResponse && (
            <Box mt={3}>
              <LoadingAnimation title="Waiting for device response" />
            </Box>
          )
        }
        {showResponse && !showWaitingResponse && (
          <Box mt={3}>
            <InputLabel htmlFor="response" className={classes.responseLabel}>
              Response
            </InputLabel>
            <ReactJson
              src={iotMessages ? cleanAllNullValues(iotMessages) : {}}
              theme="rjv-default"
              collapsed={false}
              displayDataTypes={false}
              enableClipboard
            />
          </Box>
        )}
      </Box>

    </Box>
  );
};

export default DeviceToCloud;
