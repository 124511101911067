function replacePropertyValue(obj, keyToReplace, newValue) {
  // Convert the object to a JSON string
  let jsonString = JSON.stringify(obj);

  // This regex looks for occurrences of `"CRON":` followed directly by one or more digits.
  const regex = new RegExp(`"${keyToReplace}":\\d+`, 'g');

  // Replace all matches with the new value
  jsonString = jsonString.replace(regex, `"${keyToReplace}":${newValue}`);

  // Parse the modified JSON string back into an object
  return JSON.parse(jsonString);
}

export default replacePropertyValue;
