/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import AddIcon from '@material-ui/icons/Add';
import GraphqlService from '../../../../service/graphqlService';
import PageBanner from '../../../../components/utils/PageBanner';

const useStyles = makeStyles((theme) => ({
  separate: {
    padding: 24,
  },
  table: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  selectProject: {
    width: '100%',
    maxWidth: 450,
  },
  background: {
    backgroundColor: theme.palette.background.typography,
  },
  button: {
    float: 'right',
    margin: '0 24px 24px 0',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 16,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: 12,
  },
}));

export default function FineGrainedPermissions() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [newDevice, setNewDevice] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);

  const getProjects = async () => GraphqlService.getProjects();

  const {
    data: projectsData,
  } = useQuery(['getProjects'], () => getProjects(), {
    cacheTime: 10000,
  });

  const { data: devices, refetch } = useQuery(
    ['devicesPermissions', selectedBrand],
    () => GraphqlService.getDevicesPermissionsByProject(selectedBrand?.code),
    { enabled: !!selectedBrand },
  );

  const createDeviceMutation = useMutation(
    (device) => GraphqlService.createDevicePermission(selectedBrand?.code, device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
        setNewDevice('');
      },
    },
  );

  const deleteDeviceMutation = useMutation(
    (device) => GraphqlService.deleteDevicePermission(selectedBrand?.code, device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
      },
    },
  );

  const deleteAllDevicesMutation = useMutation(
    () => GraphqlService.deleteAllDevicesPermissions(selectedBrand?.code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
      },
    },
  );

  const handleCreateDevice = () => {
    createDeviceMutation.mutate(newDevice);
  };

  const handleDeleteDevice = (device) => {
    deleteDeviceMutation.mutate(device);
  };

  const handleDeleteAllDevices = () => {
    deleteAllDevicesMutation.mutate();
  };

  return (
    <Box>
      <PageBanner title="FINE GRAINED PERMISSIONS" />
      <Grid item xs={12} className={classes.separate}>
        <FormControl className={classes.selectProject}>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <TextField
              select
              variant="outlined"
              className={classes.selectProject}
              label="Select project"
              value={selectedBrand?.code || ''}
              onChange={(e) => {
                setSelectedBrand(
                  projectsData?.find((project) => project.code === e.target.value),
                );
              }}
              style={{ marginRight: '16px' }}
            >
              {projectsData
                ?.sort((a, b) => a.code - b.code)
                .map((project) => (
                  <MenuItem key={project.code} value={project.code}>
                    {`${project.code} - ${project.name}`}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box mt={2} display="flex">
            <TextField
              label="New Device"
              value={newDevice}
              onChange={(e) => setNewDevice(e.target.value)}
              variant="outlined"
              style={{ width: '100%' }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateDevice}
              disabled={!newDevice}
              style={{ marginLeft: '8px', width: '230px' }}
              startIcon={<AddIcon />}
            >
              Add Device
            </Button>

          </Box>
        </FormControl>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="fine grained permissions table">
            <TableHead>
              <TableRow>
                <TableCell>Device</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.map((device) => (
                <TableRow key={device}>
                  <TableCell>{device}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteDevice(device)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
