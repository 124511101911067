/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  detailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
  button: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  downloadButton: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#F3916A',
    '&:hover': {
      backgroundColor: 'rgb(248, 107, 51)',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

function TestDetailPopup({ testDetail, renderOpener }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dialogRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* Button to open the dialog */}
      {renderOpener({ onClick: handleClickOpen })}

      {/* Dialog for showing bundle details */}
      <Dialog
        open={open}
        ref={dialogRef}
        onClose={handleClose}
        aria-labelledby="bundle-detail-dialog-title"
        maxWidth="md"
        fullWidth
      >

        <div className={classes.dialogTitleContainer}>
          <Typography className={classes.dialogTitleText}>
            Test Detail
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>

          <Box>
            <Box display="flex" flexDirection="row">
              <Box mr={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Test Id:
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {testDetail.test_id}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row">
              <Box mr={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  E2E Execution Id:
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {testDetail.e2e_execution_id}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Title:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.title}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Suite:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.suite}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Environment:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.environment}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Criticality:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.criticality}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Brand:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.brand}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Error Category:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.error_category}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Brand Importance:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.brand_importance}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Service Brand Relevance:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.service_brand_relevance}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Error Frequency:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.error_frequency}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Error Severity:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.error_severity}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Result:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.result}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Started At:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.started_at}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Duration:
              </Typography>
            </Box>
            <Box>
              <Typography>
                {testDetail.duration}
              </Typography>
            </Box>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.open(testDetail.logsPresignedUrl, '_blank');
            }}
            disabled={!testDetail.logsPresignedUrl}
            color="secondary"
            className={classes.downloadButton}
          >
            Download Test File
          </Button>

          <Button onClick={handleClose} color="primary" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TestDetailPopup.propTypes = {
  testDetail: PropTypes.object.isRequired,
  renderOpener: PropTypes.func.isRequired,
};

export default TestDetailPopup;
