/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, InputLabel, makeStyles, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, withStyles,
} from '@material-ui/core';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import SearchButton from '../../components/utils/SearchButton';
import {
  REGEX_SERIAL_NUMBER,
} from '../../utils/constants';
import MachineTelemetryConsumption from './MachineTelemetry/MachineTelemetryConsumption';
import DateRangePicker from '../../components/utils/DateRangePicker';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  topContent: {
    marginTop: 20,
    marginLeft: 8,
  },
  contentInfo: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  topField: {
    minHeight: 76,
    marginTop: 20,
  },

  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  bottomRightButton: {
    borderRadius: 12,
    width: 138,
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 2,
    },
  },
  picker: {
    '& .MuiIconButton-root': {
      color: 'black',
    },
    width: '100%',
  },
  bottomContent: {
    paddingTop: 16,
    paddingBottom: 12,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    height: '48px',
  },
  // machine info card
  machineInfoPaddingTop: {
  },
  machineInfoCardPrimaryText: {
    color: theme.palette.primary.text,
  },
  machineInfoCardPaddingBottomTop: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  machineInfoCardTitle: {
    display: 'flex',
  },
  machineInfoCardSectionContent: {
    display: 'flex',
    backgroundColor: theme.palette.background.typography,
    borderRadius: 15,
  },
  machineInfoCardSectionContentModules: {
    backgroundColor: theme.palette.background.typography,
    borderRadius: 15,
    marginRight: 24,
  },
  machineInfoCardFontSize16: {
    fontWeight: theme.typography.h11.fontWeight,
    fontFamily: theme.typography.h11.fontFamily,
    lineHeight: theme.typography.h11.lineHeight,
    letterSpacing: theme.typography.h11.letterSpacing,
    fontSize: theme.typography.h11.fontSize,
    color: theme.palette.primary.text,
    paddingBottom: 4,
  },
  machineInfoCardSubTitle: {
    display: 'flex',
    paddingLeft: 16,
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  machineInfoCardFontSize14: {
    color: theme.palette.primary.text,
    fontFamily: theme.typography.h11.fontFamily,
    lineHeight: theme.typography.h11.lineHeight,
    letterSpacing: theme.typography.h11.letterSpacing,
    fontSize: theme.typography.h11.fontSize,
  },
  machineInfoCardPaddingLeft16: {
    paddingLeft: 16,
    paddingTop: 10,
  },
  machineInfoCardPaddingLeft16NoTop: {
    paddingLeft: 16,
  },
  machineInfoCardExtraField: {
    display: 'flex',
    paddingLeft: 16,
    marginTop: 4,
    marginBottom: 8,
  },
  machineInfoCardFontMargin: {
    fontSize: 14,
  },
  machineInfoCardFlex: {
    display: 'flex',
    backgroundColor: theme.palette.background.typography,
    borderRadius: 15,
  },
  machineInfoCardPaddingLeft32: {
    paddingLeft: 32,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  machineInfoTitle: {
    paddingLeft: 8,
  },
  machineInfoSubSection: {
    paddingBottom: '0 !important',
  },
  machineInfoSubSection12: {
    marginBottom: 12,
  },
}));

const FirmwaresNamesStartDot = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.subMain,
    height: 8,
    width: 8,
    borderRadius: 90,
    marginRight: 8,
    marginBottom: 3,
    marginTop: 4,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}))(Box);

export default function MachineEvents({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [lifecyclePage, setLifecyclePage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  const [rowsPerPageFOTA] = useState(100);
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  // data
  const [selectedSerialNumber, setSelectedSerialNumber] = useState();
  const [serialNumberError, setSerialNumberError] = useState(false);
  const [serialNumberRegexError, setSerialNumberRegexError] = useState(false);
  const [serialNumberModelRegexError, setSerialNumberModelRegexError] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-30, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-30, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));
  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);
  const [reducedResponse, setReducedResponse] = useState(null);
  const [machineInfo, setMachineInfo] = useState(null);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // validations

  function datesValid(dateStart, dateEnd, timeStart, timeEnd) {
    let validation = true;

    const dateStartAux = moment(`${dateStart.format('YYYY-MM-DD')} ${timeStart}`, 'YYYY-MM-DD HH:mm');
    const dateEndAux = moment(`${dateEnd.format('YYYY-MM-DD')} ${timeEnd}`, 'YYYY-MM-DD HH:mm');

    if (dateStartAux > dateEndAux) {
      setGreaterSmallerDateError(true);
      validation = false;
    }

    if (moment(dateEnd).diff(moment(dateStart), 'days', true) > MAX_DAYS_BETWEEN_DATES) {
      // validate 30 days for Telemetry tab
      setMoreThanMaxDaysDateError(true);
      validation = false;
    }

    return validation;
  }

  function serialNumberValidOrEmpty(serialNumber) {
    const validation = (!serialNumber || (serialNumber.length > 0 && (REGEX_SERIAL_NUMBER).test(serialNumber)));
    return validation;
  }

  function validate(serialNumber, dateStart, dateEnd, timeStart, timeEnd) {
    let validates = true;
    if (!serialNumber) {
      setSerialNumberError(true);
      validates = false;
    }
    if (!serialNumberValidOrEmpty(serialNumber)) {
      setSerialNumberRegexError(true);
      validates = false;
    }

    if (!dateStart.isValid()) {
      setDateStartError(true);
      validates = false;
    } else if (!dateEnd.isValid()) {
      setDateEndError(true);
      validates = false;
    } else if (!datesValid(dateStart, dateEnd, timeStart, timeEnd)) {
      validates = false;
    }

    return validates;
  }

  async function searchConsumption(myProject, serialNumber, startDate, endDate, newPage) {
    const consumptionResponse = await GraphqlService.getMachineTelemetryConsumption(
      myProject.code, serialNumber, startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption,
    );
    setMachineInfo(consumptionResponse.machineInfo);
    // map the result for each call in a unique response
    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return consumptionResponse.machineTelemetryConsumption;
    }
    return [];
  }

  function calculateStartDateTimeIso(startDate, startTime) {
    const startDateTime = moment(`${startDate?.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm');
    const startOffset = startDateTime.utcOffset();
    const startDateTimeIso = startDateTime.utcOffset(0).add(startOffset, 'minutes').toISOString(true);
    return startDateTimeIso;
  }

  function calculateEndDateTimeIso(endDate, endTime) {
    const endDateTime = moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm');
    const endOffset = endDateTime.utcOffset();
    const endDateTimeIso = endDateTime.utcOffset(0).add(endOffset, 'minutes').toISOString(true);
    return endDateTimeIso;
  }

  async function search(myProject, serialNumber, startDate, endDate, startTime, endTime, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchConsumption(myProject, serialNumber, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), newPage);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = (data) => {
    setDateRangePickerVisibility(!dateRangePickerVisibility);
    if (validate(selectedSerialNumber, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime)) {
      search(
        project, selectedSerialNumber, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedSerialNumber, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setSerialNumberError(false);
    setSerialNumberRegexError(false);
    setSerialNumberModelRegexError(false);
  }, [project]);

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE ACTIVITY" />

      <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          className={classes.topContent}
        >

          {/* device serial */}
          <Grid item xs={12} md={4} className={classes.topField}>
            <TextField
              id="inputTelemetryMachine"
              label="Device serial"
              className={classes.formField}
              variant="filled"
              InputProps={{
                maxLength: 128,
              }}
              disabled={submitting}
              value={selectedSerialNumber}
              autoFocus
              error={serialNumberError}
              onChange={(event) => {
                setSerialNumberError(!event.target.value);
                setSelectedSerialNumber(event.target.value);
                setSerialNumberRegexError(false);
                setSerialNumberModelRegexError(false);
              }}
              color="secondary"
            />
            {serialNumberError && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
            {serialNumberRegexError && <Alert severity="error" className={classes.errorAlert}>Device serial only allows numbers and letters</Alert>}
            {serialNumberModelRegexError && <Alert severity="error" className={classes.errorAlert}>Device serial does not match with project</Alert>}
          </Grid>

          <Grid item xs={12} md={4} className={classes.picker}>
            <DateRangePicker
              initialStartDate={new Date()}
              initialEndDate={new Date()}
              onDateChange={handleDateChange}
              setSelectedStartDate={setSelectedStartDate}
              selectedStartDate={selectedStartDate}
              setSelectedStartTime={setSelectedStartTime}
              selectedStartTime={selectedStartTime}
              setSelectedEndDate={setSelectedEndDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndTime={setSelectedEndTime}
              selectedEndTime={selectedEndTime}
              datesOptional
              maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
              setVisibility={dateRangePickerVisibility}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.bottomRightButton}>
            <SearchButton
              id="btnTelemetrySearch"
              type="submit"
              className={classes.bottomRightButton}
            >
              SEARCH
            </SearchButton>
          </Grid>

        </Grid>

      </form>

      <Grid item xs={12} className={classes.contentInfo}>
        {(machineInfo !== null) && (
          <Grid
            container
            spacing={0}
            direction="row"
            className={classes.machineInfoPaddingTop}
          >
            <Grid item xs={12}>
              <Accordion className={classes.accordion} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.machineInfoCardPrimaryText} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3} className={classes.machineInfoTitle}>
                      <Typography variant="h2" className={classes.machineInfoCardPaddingBottomTop}>Device Info</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Grid container>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                    >
                      <Grid item xs={12} className={classes.machineInfoSubSection}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="stretch"
                        >
                          <Grid item xs={12} className={classes.machineInfoCardTitle}>
                            <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Device </Typography>
                          </Grid>

                          <Grid
                            item
                            container
                            xs={12}
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            className={classes.machineInfoCardSectionContent}
                          >

                            <Grid item xs={2} className={classes.machineInfoCardPaddingLeft16}>
                              <FirmwaresNamesStartDot />
                              <Typography className={classes.detailsTitle} variant="title" noWrap>File name </Typography>
                              <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                                <Typography id="txtFileNameDevice" display="inline" className={classes.machineInfoCardFontSize14}>
                                  v
                                  {' '}
                                  {machineInfo.deviceFw}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={10} className={classes.machineInfoCardPaddingLeft16}>
                              <FirmwaresNamesStartDot />
                              <Typography className={classes.detailsTitle} variant="title" noWrap>Extra fields </Typography>
                              {machineInfo.extraDeviceFw.map((resp, i) => (
                                <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                                  <Typography id={`txtExtraFieldDevice_${i}`} className={classes.machineInfoCardFontSize14}>
                                    {resp.field}
                                  </Typography>
                                  <Typography id={`txtExtraValueDevice_${i}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                                    {':  '}
                                    v
                                    {' '}
                                    {resp.value}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                      {(machineInfo.gatewayApp !== null) && (
                        <Grid item xs={12} className={classes.machineInfoSubSection}>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="stretch"
                          >
                            <Grid item xs={12} className={classes.machineInfoCardTitle}>
                              <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Gateway </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              rowSpacing={3}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              className={classes.machineInfoCardSectionContent}
                            >
                              <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16}>
                                <FirmwaresNamesStartDot />
                                <Typography className={classes.detailsTitle} variant="title" noWrap>Asset </Typography>
                                <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                                  <Typography id="txtFirmwareGateway" className={classes.machineInfoCardFontSize14} variant="subheading" noWrap>
                                    v
                                    {' '}
                                    {machineInfo.gatewayApp}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {(machineInfo.modules.length > 0) && (
                        <Grid item xs={12} className={classes.machineInfoSubSection12}>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="stretch"
                          >
                            <Grid item xs={12} className={classes.machineInfoCardTitle}>
                              <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Modules </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              rowSpacing={3}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              {machineInfo.modules.map((resp, i) => (
                                <Grid item xs={4} className={classes.machineInfoCardSectionContentModules}>
                                  <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16}>
                                    <FirmwaresNamesStartDot />
                                    <Typography className={classes.detailsTitle} variant="title" noWrap>Module </Typography>
                                    <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                                      <Typography id={`txtSerialModules_${i}`} className={classes.machineInfoCardFontSize14} variant="subheading">{resp.serial}</Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16NoTop}>
                                    <FirmwaresNamesStartDot />
                                    <Typography className={classes.detailsTitle} variant="title" noWrap>Asset </Typography>
                                    <Grid item xs={12} className={classes.machineInfoCardExtraField}>

                                      <Typography id={`txtFirmwareModules_${i}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                                        v
                                        {' '}
                                        {resp.moduleFw}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FirmwaresNamesStartDot />
                                      <Typography className={classes.detailsTitle} variant="title" noWrap>Extra fields </Typography>
                                      {resp.extraDeviceFw.map((respExtra, j) => (
                                        <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                                          <Typography id={`txtExtraFieldModule_${i}_${j}`} className={classes.machineInfoCardFontSize14}>
                                            {respExtra.field}
                                          </Typography>
                                          <Typography id={`txtExtraValueModule_${i}_${j}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                                            {':  '}
                                            v
                                            {' '}
                                            {respExtra.value}
                                          </Typography>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <MachineTelemetryConsumption
          search={(newPage) => search(project, selectedSerialNumber, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
          dataList={reducedResponse}
          totalCount={totalCount}
          serialNumber={selectedSerialNumber}
          loading={loading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          openAlert={openAlert}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={rowsPerPageConsumption}
        />
      </Grid>
    </Box>
  );
}
MachineEvents.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
