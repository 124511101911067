/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import JSONBuilderFilters, { valueTypes } from '../../components/utils/JSONBuilderFilters';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  contentInfo: {
    paddingBottom: 8,
    backgroundColor: theme.palette.background.typography,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const jsonColumns = [

  {
    id: 'id', label: 'ID', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'device_serial', label: 'Device Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'module_serial', label: 'Module Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'event_code', label: 'Event Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'is_valid_time', label: 'Is Valid Time', valueType: valueTypes.BOOLEAN, values: ['true', 'false'],
  },
  {
    id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'created_on_local', label: 'Created On Local', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'created_on_utc', label: 'Created On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
  },
];
export default function BrandEvents({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  const params = { add_dict: true };
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(myProject, filters, startDate, endDate, newPage) {
    setDateRangePickerVisibility(!dateRangePickerVisibility);
    const consumptionResponse = await GraphqlService.getTelemetryObject(
      myProject.code, 'events', JSON.stringify(params), filters, startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption, MAX_DAYS_BETWEEN_DATES,
    );
    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return JSON.parse(consumptionResponse.data);
    }
    return [];
  }

  async function search(myProject, filters, startDate, endDate, startTime, endTime, newPage) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(myProject, filters, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), newPage);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSearch = (event, filters) => {
    event.preventDefault();
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(
        project, filters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE EVENTS" />
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
          className={classes.contentInfo}
        >

          <JSONBuilderFilters
            id="DeviceEvents"
            setSelectedFilters={setSelectedFilters}
            submitting={submitting}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndTime={selectedEndTime}
            setSelectedEndTime={setSelectedEndTime}
            filtersVisible
            deviceSerialVisible={false}
            jsonColumns={jsonColumns}
            setDateRangePickerVisibility={dateRangePickerVisibility}
            onSearch={onSearch}
          />

        </Grid>

        <Grid item xs={12}>
          <BrandObjectTable
            search={(newPage) => search(project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
            dataList={reducedResponse}
            totalCount={totalCount}
            filters={selectedFilters}
            loading={loading}
            dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
            dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
            rangeDates={MAX_DAYS_BETWEEN_DATES}
            page={reducedPage}
            setPage={setReducedPage}
            rowsPerPage={rowsPerPageConsumption}
            object="events"
            params={JSON.stringify(params)}
            id="DeviceEvents"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
BrandEvents.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
