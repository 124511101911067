const changelog = `# Updates
All notable changes to this project will be documented in this page.

Stay updated with every version's enhancements and optimizations to ensure you're making the most out of our application.

The format is based on [Keep a Changelog](https://keepachangelog.com/en/1.0.0/),
and this project adheres to [Semantic Versioning](https://semver.org/spec/v2.0.0.html).

## v3.13.0 (20.01.2025)

**New**

- **IoT Platform Health:** Admin users now have the capability to monitor the Platform Health directly within the Portal.

**Updated**

- The "New IoT Asset Version Analysis and Asset Deployment" ServiceNow form has been enhanced and is now available in the Service Request menu.

- A "Raise an Incident" link has been added to the Service Request menu, allowing users to easily redirect to ServiceNow to raise an incident directly from the Portal.

## v3.12.0 (17.12.2024)

**New**

- **Simulated Client as a Service:** users can now simulate MQTT messages from device to cloud. This new feature offers a great support to the development integration activities.

**Updated**

- Bug Fixes

## v3.11.0 (27.11.2024)

**Updated**

- **Error messages:** conveying of error messages from the platform APIs is update all across the Portal.
- Bug Fixes in Bundles
- Bug Fixes in Waves

## v3.10.0 (30.10.2024)
 
**New**
 
- **Waves:** a wave entity is created for deployment of assets or asset bundles which increases traceability. User is able to delete all deployments associated with the wave in one transaction.

## v3.9.0 (09.10.2024)

**New**

- **Copy json:** users can now copy and visualize json in different pages in asset and deployment pages in the portal.
- **Filters in deployment details:** users can now apply filter to their search in deployment details.

**Updated**

- **Color code in Certificate Management:** threshold of certificate quota are now translated in color code.
- **Bundles list in asset details:** now users are informed about the bundles one asset is part of.

## v3.8.0 (02.10.2024)

**Updated**

- **Deploy Capacity Update:** Now users can deploy up to 500 serial numbers in the same wave.

**New**

- **Certificate Management:** Through this new feature users are now able to manage quotas for specifics user IDs and check certificate status.

## v3.7.0 (23.09.2024)
 
**New**
 
- **Secure Remote Functions:**  Enables developers to mock SRF Backend call (in the context of a test brand ) to the IoT Hub sending a secure message to an specific Simulated Device.
 
- **Give Feedback Submission:** Enables users to give feedback directly through the new icon in the toolbar. 

## v3.6.0

**New**

- **SSO Production:**  Enables user authentication across Nestle single sign-on system. Users no longer need to provide their email for login. However, you should request permission for access upon first use.
`;

export default changelog;
