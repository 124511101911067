import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import SignOutButton from './utils/SignOutButton';

const useStyles = makeStyles((theme) => ({
  typography: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 30,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.subMain,
    fontFamily: theme.typography.h5.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 2,
    marginRight: 2,
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 13,
    backgroundColor: theme.palette.primary.subMain,
  },
  avatarText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 8,
    marginRight: 8,
    fontSize: 16,
  },
  avatarArrow: {
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 24,
  },
  popover: {
    width: '70%',
    marginLeft: 50,
  },

}));

function clearCognitoKeys() {
  // Iterate through all keys in localStorage
  Object.keys(localStorage).forEach((key) => {
    // Check if the key includes 'CognitoIdentityServiceProvider'
    if (key.includes('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key); // Remove the matching key
    }
  });
}

function CustomSignoutButton() {
  const signOut = async (e) => {
    e.preventDefault();
    clearCognitoKeys();
    window.location.reload();
  };

  return (
    <SignOutButton
      type="button"
      onClick={signOut}
      style={{
        width: '100%',
        borderRadius: 12,
        marginLeft: 6,
        marginRight: 6,
        marginBottom: 6,
        height: 40,
      }}
    >
      Sign out
    </SignOutButton>
  );
}

function Profile({ user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        onClick={handleClick}
      >
        <Grid item>
          <Avatar
            aria-describedby={id}
            alt={user}
            className={classes.avatar}
          >
            {user.substring(0, 1).toUpperCase()}
          </Avatar>

        </Grid>
        <Grid item className={classes.avatarText} id="user-header-email">
          {user}
        </Grid>
        <Grid item className={classes.avatarArrow}>
          <ArrowDropDownIcon />
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popover}
      >
        <Grid container justifyContent="left" alignItems="center">
          <Typography className={classes.typography}>{user}</Typography>
          <CustomSignoutButton />
        </Grid>
      </Popover>
    </div>
  );
}

Profile.propTypes = {
  user: PropTypes.string.isRequired,
};

export default Profile;
