/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */

import {
  Box, Grid, Typography, makeStyles, Link, Tooltip,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PageBanner from '../../components/utils/PageBanner';
import { config } from '../../config/awsExports';

import { ReactComponent as CertificateIcon } from '../../assets/support/ic_support_certifiactes.svg';
import { ReactComponent as NesspresoIoTIcon } from '../../assets/support/ic_support_nesspreso_iot.svg';
import { ReactComponent as FirmwareIcon } from '../../assets/support/ic_support_firmware_version.svg';
import { ReactComponent as ApiIcon } from '../../assets/support/ic_support_api.svg';
import { ReactComponent as IoTDeviceEnviromentIcon } from '../../assets/support/ic_support_iot_device_enviroment.svg';
import { ReactComponent as DataSharingIcon } from '../../assets/support/ic_support_data_sharing.svg';
import { ReactComponent as IoTPlatformIcon } from '../../assets/support/ic_support_iot_costumer_platform.svg';
import { ReactComponent as IotNesspresoPortalIcon } from '../../assets/support/ic_support_nesspreso_portal.svg';

import {
  URL_RAISE_INCIDENT,
} from '../../utils/constants';

// eslint-disable-next-line import/no-dynamic-require
const { env } = config;

const useStyles = makeStyles((theme) => ({
  cardSupport: {
    backgroundColor: theme.palette.primary.bannerText,
    height: 210,
    width: '226px',
    borderRadius: 12,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 22,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 24,
  },
  contendCard: {
    paddingTop: 16,
    paddingBottom: 16,
    textAlign: 'center',
    paddingLeft: 29,
    paddingRight: 28,
  },
  iconSupport: {
    marginBottom: 16,
    height: 32,
    width: 32,
  },
  iconOutSupport: {
    color: 'grey',
    height: 16,
    width: 16,
    marginLeft: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleSupport: {
    fontWeight: theme.typography.h12.fontWeight,
    fontFamily: theme.typography.h12.fontFamily,
    lineHeight: theme.typography.h12.lineHeight,
    letterSpacing: theme.typography.h12.letterSpacing,
    fontSize: theme.typography.h12.fontSize,
    color: theme.palette.primary.main,
    marginBottom: 16,
    textAlign: 'center',
  },
  titleSupportLong: {
    fontWeight: theme.typography.h12.fontWeight,
    fontFamily: theme.typography.h12.fontFamily,
    lineHeight: theme.typography.h12.lineHeight,
    letterSpacing: theme.typography.h12.letterSpacing,
    fontSize: theme.typography.h12.fontSize,
    color: theme.palette.primary.main,
    marginBottom: 8,
    textAlign: 'center',
  },
  subtitleSupport: {
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    color: theme.palette.secondary.subText,
    paddingBottom: 16,
    textAlign: 'left',
  },
  subtitleSupportShorts: {
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    color: theme.palette.secondary.subText,
    paddingBottom: 16,
    textAlign: 'left',
  },
  subtitleSupportbWhite: {
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    color: theme.palette.secondary.subText,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.h7.fontWeight,
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    '&:hover': {
      color: '#324149',
      textDecoration: 'none',
    },
  },
  cardFooterSupport: {
    backgroundColor: theme.palette.primary.subFooter,
    width: '100%',
    minHeight: 35,
    borderRadius: 12,
    marginLeft: 8,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'static',
  },
  textFooterReport: {
    fontFamily: theme.typography.h11.fontFamily,
    lineHeight: theme.typography.h11.lineHeight,
    letterSpacing: theme.typography.h11.letterSpacing,
    fontSize: theme.typography.h11.fontSize,
    color: theme.palette.secondary.text,
    marginLeft: 16,
  },
  openContent: {
    display: 'flex',
    alignItems: 'center',
  },
  spaceBannerAccess: {
    paddingTop: 24,
  },
  spaceFooter: {
    paddingTop: 0,
  },
}));

export default function Support() {
  const classes = useStyles();

  return (
    <Box style={{ height: '100%', width: '100%' }}>
      <PageBanner title="SERVICE REQUEST" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >

        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          style={{ paddingLeft: '24px' }}
        >
          <Grid item className={classes.cardSupport} id="certificate-quota">
            <Grid className={classes.contendCard}>
              <CertificateIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}>Certificates Quota</Typography>
              <Typography className={classes.subtitleSupport}>Modify certificates quota, to add new ones</Typography>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportCertificatesQuota" target="_blank" className={classes.link} href={env.certificateUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.cardSupport} id="request-review">
            <Grid className={classes.contendCard}>
              <NesspresoIoTIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}> Request for review – Nespresso IoT B2B</Typography>
              <Tooltip title="Request to review an issue with Nespresso IoT B2B (Skyline, etc)" arrow>
                <Typography className={classes.subtitleSupport}>Request to review an ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportRequestReview" target="_blank" className={classes.link} href={env.nesspresoB2BUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.cardSupport} id="new-iot-asset">
            <Grid className={classes.contendCard}>
              <FirmwareIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}> New IoT Asset version Analysis and Asset Deploy</Typography>
              <Tooltip title="Request a new asset version to be analyzed for future deployment" arrow>
                <Typography className={classes.subtitleSupport}>Request a new ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportNewIoTAsset" target="_blank" className={classes.link} href={env.firmwareUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.cardSupport} id="api-credentials">
            <Grid className={classes.contendCard}>
              <ApiIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}>API Credentials</Typography>
              <Typography className={classes.subtitleSupport}>Request API credentials</Typography>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportAPICredentials" target="_blank" className={classes.link} href={env.apiUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.cardSupport} id="iot-device">
            <Grid className={classes.contendCard}>
              <IoTDeviceEnviromentIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}>New IoT Device Environment</Typography>
              <Tooltip title="Request a Staging environment for a new IoT Device including Telemetry Sandbox, FOTA, and Remote Functions" arrow>
                <Typography className={classes.subtitleSupport}>Request a Staging ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportNewIoTDeviceEnvironment" target="_blank" className={classes.link} href={env.deviceEnviromentUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.cardSupport} id="data-sharing">
            <Grid className={classes.contendCard}>
              <DataSharingIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}>IoT Data Sharing</Typography>
              <Tooltip title="Request Data Sharing use case creation/modification" arrow>
                <Typography className={classes.subtitleSupport}>Request Data ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Link id="btnOpenSupportIoTDataSharing" target="_blank" className={classes.link} href={env.dataSaringUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </Box>

      <Grid item xs={3} className={classes.spaceBannerAccess} />

      <PageBanner title="ACCESS REQUEST" style={{ marginTop: '256px' }} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '22px' }}
      >

        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          style={{ paddingLeft: '24px' }}
        >
          <Grid item className={classes.cardSupport}>
            <Grid className={classes.contendCard} id="request-access">
              <IoTPlatformIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}> Consumer IoT Platform Portal</Typography>
              <Tooltip title="Request access to Consumer IoT Platform Portal" arrow>
                <Typography className={classes.subtitleSupport}>Request access to ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Link id="btnOpenSupportConsumerIoTPlatformPortal" target="_blank" className={classes.link} href={env.costumerPlatformUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.cardSupport} id="user-request">
            <Grid className={classes.contendCard}>
              <IotNesspresoPortalIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupport}>IoT Nespresso Portal</Typography>
              <Tooltip title="IoT Nespresso Portal - User create/modify request" arrow>
                <Typography className={classes.subtitleSupport}>IoT Nespresso Portal - User ..</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Link id="btnOpenSupportIoTNespressoPortal" target="_blank" className={classes.link} href={env.nesspresoPortalUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.cardSupport} id="access-nespresso-cloud2">
            <Grid className={classes.contendCard}>
              <ApiIcon className={classes.iconSupport} />
              <Typography className={classes.titleSupportLong}>Nestle Professional Cloud2Cloud API Integration</Typography>
              <Tooltip title="Request access to Nespresso Cloud 2 Cloud API" arrow>
                <Typography className={classes.subtitleSupport}>Request access to ...</Typography>
              </Tooltip>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Link id="btnOpenSupportNestleProfessional" target="_blank" className={classes.link} href={env.apiCloud2CloudUrl}>
                  <div className={classes.openContent}>
                    OPEN
                    <OpenInNewIcon className={classes.iconOutSupport} />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Box>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.cardFooterSupport}>
          <Typography className={classes.textFooterReport}>Having problems with Consumer IoT Platform? </Typography>
          <Link id="btnSupportForm" target="_blank" className={classes.link} href={URL_RAISE_INCIDENT}>
            &nbsp;
            <u>Raise an incident</u>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
