/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box, makeStyles, Paper, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import BannerStyledButton from './BannerStyledButton';
import Restricted from '../../authorization/Restricted';

// Utility to observe if an element is in view
const useIsInView = (ref, options = {}) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isInView;
};

// AnimatedText component directly in PageBanner
const AnimatedText = ({ text }) => {
  const controls = useAnimation();
  const textArray = Array.isArray(text) ? text : [text];
  const ref = useRef(null);
  const isInView = useIsInView(ref, { amount: 0.5, once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [isInView]);

  const defaultAnimations = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.1 } },
  };

  return (
    <motion.span
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { transition: { staggerChildren: 0.1 } },
        hidden: {},
      }}
      aria-hidden
    >
      {textArray.map((line, lineIndex) => (
        <span className="block" key={`${line}-${lineIndex}`}>
          {line.split(' ').map((word, wordIndex) => (
            <span className="inline-block" key={`${word}-${wordIndex}`}>
              {word.split('').map((char, charIndex) => (
                <motion.span
                  key={`${char}-${charIndex}`}
                  className="inline-block"
                  variants={defaultAnimations}
                >
                  {char}
                </motion.span>
              ))}
              <span className="inline-block">&nbsp;</span>
            </span>
          ))}
        </span>
      ))}
    </motion.span>
  );
};

AnimatedText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

// Main PageBanner Component
export default function PageBanner(props) {
  const useStyles = makeStyles((theme) => ({
    bannerText: {
      float: 'left',
      textAlign: 'left',
      paddingLeft: 24,
      paddingTop: 24,
      paddingBottom: 24,
      color: theme.palette.background.paper,
    },
    bannerButton: {
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
    },
    bannerContainer: {
      zIndex: theme.zIndex.appBar - 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.primary.banner,
    },
    buttonSection: {
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  }));

  const {
    title, buttons, buttonIcons, buttonDisabled, onClicks, buttonPermission, id,
  } = props;

  function BannerButton(bbProps) {
    return (
      <BannerStyledButton
        id={id}
        startIcon={buttonIcons[bbProps.index]}
        onClick={onClicks[bbProps.index]}
        disabled={buttonDisabled[bbProps.index]}
        style={{
          borderRadius: 12, paddingTop: 8, paddingBottom: 8, color: 'white',
        }}
      >
        {buttons[bbProps.index]}
      </BannerStyledButton>
    );
  }

  const classes = useStyles();

  return (
    <Box className={classes.bannerContainer} component="div" elevation={0}>
      <div className={classes.bannerText}>
        <Typography variant="h2">
          {title}
        </Typography>
      </div>
      <div className={classes.buttonSection}>
        {buttons && buttons.map((button, index) => button
          && (
            <div key={button} className={classes.bannerButton}>
              {(button && buttonPermission)
                ? (
                  <Restricted to={[buttonPermission]}>
                    <BannerButton index={index} />
                  </Restricted>
                )
                : ((button) && (
                  <BannerButton index={index} />
                ))}
              <Paper elevation={0} />
            </div>
          ))}
      </div>
    </Box>
  );
}

PageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.string),
  buttonIcons: PropTypes.arrayOf(PropTypes.object),
  buttonDisabled: PropTypes.arrayOf(PropTypes.bool),
  onClicks: PropTypes.arrayOf(PropTypes.func),
  buttonPermission: PropTypes.string,
  id: PropTypes.string,
};

PageBanner.defaultProps = {
  buttons: undefined,
  buttonIcons: undefined,
  buttonDisabled: undefined,
  onClicks: undefined,
  buttonPermission: undefined,
  id: undefined,
};
